import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6529bc12"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "main-container d-f flex-d-c" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Breadcrumb = _resolveComponent("Breadcrumb")!
  const _component_Homepage_Bu = _resolveComponent("Homepage-Bu")!
  const _component_a_tab_pane = _resolveComponent("a-tab-pane")!
  const _component_a_tabs = _resolveComponent("a-tabs")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Breadcrumb, {
      dataSource: [],
      class: "breadcrumb-planning"
    }),
    _createVNode(_component_a_tabs, {
      type: "card",
      activeKey: _ctx.activeKey,
      "onUpdate:activeKey": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeKey) = $event)),
      class: "tabs"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_tab_pane, {
          key: "homepage",
          tab: "Homepage",
          class: "c-333"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Homepage_Bu, { ref: "homepage" }, null, 512)
          ]),
          _: 1
        }),
        _createVNode(_component_a_tab_pane, {
          disabled: "",
          key: "guideline",
          tab: "Guideline",
          class: "c-333"
        })
      ]),
      _: 1
    }, 8, ["activeKey"])
  ]))
}