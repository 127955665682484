
import moment from 'moment'
import { useRouter } from 'vue-router'
import YearPicker from '@/views/Payment/components/YearPicker.vue'
import { defineComponent, onMounted, ref, reactive, watch } from 'vue'
import { formatPlanningStatus } from '@/utils/planning/constUtile'
import { getGuidelinePlanningYear, getReopenBuList, updateReopenBus } from '@/API/planning'
import { message } from 'ant-design-vue'
const commonCell = () => {
  return {
    style: {
      'background': '#F8F8F8'
    }
  }
}
const columns = [
  {
    title: 'Planning Scenario',
    dataIndex: 'planningName',
    key: 'planningName',
    width: 250,
    slots: { customRender: 'name' },
    customCell:()=>{		//在此处定义
      return {style:{'background': '#F8F8F8', 'paddingLeft': '20px !important'}}    //return 想要设置的样式
    }
  },
  {
    title: 'Kufri',
    dataIndex: 'kufriMonth',
    key: 'kufriMonth',
    slots: { customRender: 'kufri' },
    width: 90,
    customCell: commonCell
  },
  {
    title: 'Create Time',
    dataIndex: 'createTime',
    key: 'createTime',
    slots: { customRender: 'create' },
    width: 120,
    customCell: commonCell
  },
  {
    title: 'Operation',
    key: 'action',
    slots: { customRender: 'action' },
    width: 222,
    customCell:()=>{		//在此处定义
      return {
        style:{ 
          'paddingLeft': '20px !important',
          'font-size': '12px'
        }
      }
    }
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    slots: { customRender: 'status' },
    width: 106,
    customCell:()=>{		//在此处定义
      return {
        style:{ 
          'paddingLeft': '20px !important',
          'background': '#F8F8F8'
        }
      }
    }
  },
  {
    title: 'Lock',
    dataIndex: 'lock',
    key: 'lock',
    slots: { customRender: 'lock' },
    width: 50,
    customCell: commonCell
  },
  {
    title: '',
    key: 'simulation1',
    width: 20,
    customHeaderCell: () => {
      return {
        style: {
          border: '0px !important'
        }
      }
    },
    customCell: () => {
      return {
        style: {
          border: '0px !important',
          'backgroundColor': '#FFF !important'
        }
      }
    }
  },
  {
    title: '',
    key: 'simulation1',
    slots: { customRender: 'simulation1' },
    width: 160,
    align: 'left',
    customCell: () => {
      return {
        style: {
          'padding-left': '20px !important'
        }
      }
    }
  },
  {
    title: 'Simulation',
    key: 'simulation2',
    slots: { customRender: 'simulation2' },
    width: 120,
    customHeaderCell: () => {
      return {
        style: {
          'text-align': 'center'
        }
      }
    },
    customCell: () => {
      return {
        style: {
          'padding-left': '20px !important'
        }
      }
    }
  },
  {
    title: '',
    key: 'simulation3',
    slots: { customRender: 'simulation3' },
    width: 160,
    customCell: () => {
      return {
        style: {
          'padding-left': '20px !important'
        }
      }
    }
  }
]
export default defineComponent({
  components: {
    YearPicker
  },
  setup() {
    const router = useRouter()
    const modalShow = ref<boolean>(false)
    const reopenBuList = ref<any[]>([])
    const chooseBuList = ref<number[]>([])
    const currentPlanningId = ref<number>(0)
    const planningStatusText = formatPlanningStatus
    const currentYear = ref<string>(new Date().getFullYear().toString())
    const tableColumns = ref(columns)
    const allInfo = ref<any[]>([
      {
        name: 'CBU',
        list: []
      },
      {
        name: 'PBP',
        list: []
      },
      {
        name: 'VAN',
        list: []
      },
      // {
      //   name: 'DENZA',
      //   list: []
      // }
    ])

    const getPlanningList = async (type: string) => {
      const _params = {
        make: type,
        currentYear: currentYear.value
      }
      const _result = await getGuidelinePlanningYear(_params)
      _result.data.forEach((item: any) => {
        item.createTime = moment(item.createAt).format('YYYY.MM.DD')
      })
      return _result.data
    }

    const methodsGetAllInfo = () => {
      allInfo.value.forEach(async (item: any) => {
        if (item.name === 'CBU') {
          item.list = await getPlanningList('CBU')
        }
        if (item.name === 'PBP') {
          item.list = await getPlanningList('PBP')
        }
        if (item.name === 'VAN') {
          item.list = await getPlanningList('VAN')
        }
        // if (item.name === 'DENZA') {
        //   item.list = await getPlanningList('DENZA')
        // }
      })
    }

    const handleJump = (path: string, record: any) => {
      router.push({
        path,
        query: {
          scenarioId: record.scenarioId,
          planningId: record.id,
          year: record.year,
          scenarioName: record.name,
          lockStatus: record.lockStatus,
          kufriMonth: record.kufriMonth
        }
      })
    }

    const handleClickSimulation = (record: any) => {
      router.push({
        path: '/simulation/guideline',
        query: {
          scenarioId: record.scenarioId,
          planningId: record.id,
          year: record.year,
          scenarioName: record.name,
          lockStatus: record.lockStatus,
          kufriMonth: record.kufriMonth
        }
      })
    }

    const handleChangeYear = (date: any) => {
      currentYear.value = moment(date).format('YYYY')
      methodsGetAllInfo()
    }

    const handleReopen = async (id: number)  => {
      currentPlanningId.value = id
      try {
        const _params = {
          planningId: id
        }
        const _response = await getReopenBuList(_params)
        reopenBuList.value = _response.data
        if (reopenBuList.value?.length === 0) {
          return message.error('Data Not Submitted.')
        }
        modalShow.value = true
      } catch (error) {
        console.log(error)
      }
    }

    const handleModalOk = async () => {
      const _postData = {
        bus: chooseBuList.value,
        planningId: currentPlanningId.value
      }
      await updateReopenBus(_postData)
      modalShow.value = false
      methodsGetAllInfo()
    }

    onMounted(() => {
      methodsGetAllInfo()
    })

    return {
      modalShow,
      currentPlanningId,
      reopenBuList,
      chooseBuList,
      planningStatusText,
      currentYear,
      tableColumns,
      allInfo,
      handleReopen,
      handleChangeYear,
      handleModalOk,
      handleJump,
      handleClickSimulation
    }
  }
})
